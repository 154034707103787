import React from "react";
import ReactDOM from "react-dom";

// main application
import App from "./components/App";

// main style
import "./styles/App.scss";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
