function About() {
  return (
    <div className="Content">
      <div className="AboutBackground">
        <h2>Christopher J. Umina</h2>

        <div className="AboutBackgroundContent">
          <ul>
            <li>Boston, MA</li>
            <li>
              chris <span className="emphasis">(at)</span> uminac{" "}
              <span className="emphasis">(dot)</span> com
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/uminac"
              >
                https://github.com/uminac/
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/uminac"
              >
                https://twitter.com/uminac/
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/uminac/"
              >
                https://www.linkedin.com/in/uminac/
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="AboutTech">
        <h3>Recent Tech I've Used:</h3>

        <div className="AboutTechList">
          <div className="AboutTechListSection">
            <h4>Networking Platforms</h4>
            <ul>
              <li>Cisco</li>
              <li>Dell</li>
              <li>FreeBSD/PF</li>
              <li>Juniper</li>
              <li>Linux+Quagga/BIRD</li>
              <li>OpenBSD/PF</li>
              <li>Ubiquiti</li>
            </ul>
          </div>

          <div className="AboutTechListSection">
            <h4>Operating Systems</h4>
            <ul>
              <li>Debian</li>
              <li>FreeBSD (very extensive)</li>
              <li>OpenBSD (extensive)</li>
              <li>OSX</li>
              <li>Ubuntu (very extensive)</li>
              <li>Windows</li>
            </ul>
          </div>

          <div className="AboutTechListSection">
            <h4>IoT Software</h4>
            <ul>
              <li>AWS IoT Core (extensive)</li>
              <li>Grafana</li>
              <li>InfluxDB</li>
              <li>mosquitto (extensive)</li>
              <li>Node-RED</li>
              <li>Prometheus</li>
              <li>RabbitMQ</li>
              <li>Telegraf</li>
              <li>The Things Network</li>
            </ul>
          </div>

          <div className="AboutTechListSection">
            <h4>IoT Hardware</h4>
            <ul>
              <li>Arduino</li>
              <li>Beaglebone</li>
              <li>ESP32 (FreeRTOS)</li>
              <li>ESP8266</li>
              <li>Multitech Conduit AEP (extensive)</li>
              <li>Multitech Conduit Custom Application (extensive)</li>
              <li>Multitech mDOT/xDOT</li>
              <li>Raspberry Pi</li>
              <li>STM32</li>
            </ul>
          </div>

          <div className="AboutTechListSection">
            <h4>CI/CD & DevOps</h4>
            <ul>
              <li>Docker</li>
              <li>GitHub Actions</li>
              <li>Jenkins</li>
            </ul>
          </div>

          <div className="AboutTechListSection">
            <h4>Storage Systems</h4>
            <ul>
              <li>AWS/EBS/S3/EFS</li>
              <li>Dell/EMC</li>
              <li>
                FreeBSD/ZFS (high IOPS performance NAS for compute cluster)
              </li>
              <li>iSCSI</li>
              <li>ixSystems/TrueNAS/TrueNAS Core</li>
              <li>Linux/ZFS/XFS/btrfs</li>
              <li>MinIO</li>
              <li>NFS</li>
              <li>Oracle/ZFS</li>
              <li>Samba/CIFS/SMB (AD-joined as well)</li>
            </ul>
          </div>

          <div className="AboutTechListSection">
            <h4>Web Applications</h4>
            <ul>
              <li>AWS Serverless Architecture Model (SAM)</li>
              <li>Deployment Automation</li>
              <li>Node.js</li>
              <li>React</li>
              <li>Relational Databses (PGSQL, MySQL, AWS RDS)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
