import { Link } from "react-router-dom";

function Header() {
  return (
    <div className="Header">
      <div className="Logo">
        <Link className="LogoLink text-link" to="/">
          <h1>@uminac</h1>
        </Link>
      </div>
      <div className="Nav">
        <ul className="NavLinks">
          {/* <li>
            <Link to="Calendar" className="NavLink text-link">
              Calendar
            </Link>
          </li>
          <li>
            <Link to="projects" className="NavLink text-link">
              Projects
            </Link>
          </li> */}
        </ul>
      </div>
    </div>
  );
}

export default Header;
